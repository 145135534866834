/**
 *  COPYRIGHT © 2022 MANU BHAT
 *  
 *  ALL RIGHTS RESERVED
 * 
 */

import { Role, SkillGroup, Subrole } from "../../framework/skill_list";

import "./formal.css"

export default function Formal() {
    return (
        <SkillGroup id='formal' title="Formal Work">

            <Role
                name="UCSD ProgSys"
                description="UCSD ProgSys Group researches topics relevant to programming languages, such as compilers. Unpaid and hybrid."
                img_src="/img/ucsd-progsys.webp"
            >
                <Subrole
                    name="Undergraduate Researcher"
                    duration="2024-Present"
                >
                    I am helping formalize the correctness of a constrained-decoding algorithm using the Lean theorem prover.
                </Subrole>
            </Role>
            <Role
                name="Turakhia Lab"
                description="Turakhia Lab focuses on novel methodologies in bioinformatics. Paid and on-site"
                img_src="/img/ucsd-turakhia-lab.webp"
            >
                <Subrole
                    name="Undergraduate Researcher"
                    duration="2023-Present"
                >
                    I am responsibile for optimizing and developing algorithms pertinent to virus discovery from wastewater data. 
                    This was done through both parallelization and a linear runtime reduction. 
                </Subrole>
            </Role>

            <Role
                name="MaXentric Technologies"
                description="MaXentric is a government contractor that specializes in SBIRs. Paid and on-site."
                img_src="/img/maxentric.webp"
                href="https://maxentric.com"
            >
                <Subrole
                    name="Software Engineer Intern"
                    duration="2023"
                    >
                        I automated the calibration of radars and also modified their FreeRTOS firmware to allow loading of configuration via SD card. 
                        I developed UDP-based data transfer script to track packet loss on a V band network. 
                        In my work, I utilized Wireshark and JTAG interfaces for intricate debugging
                </Subrole>
            </Role>

            <Role
                name="UCSD Spatiotemporal Lab"
                description="UCSD STL is a research group focused on spatiotemporal systems and physics-based deep learning. Unpaid and hybrid."
                img_src="/img/ucsd-stl.webp"
            >
                <Subrole
                    name="Undergraduate Researcher"
                    duration="2022-Present"
                >
                    I am a part of the lab's work related to symmetry discovery. 
                    In particular, I helped formulate a definition of local symmetry and a method for 
                    discovering such equivariances.
                </Subrole>
            </Role>

            <Role
                name="Breakout Mentors"
                description="Breakout Mentors is a tutoring company. I work under the USA Computing Olympiad division. Paid and remote."
                img_src="/img/breakoutmentors.webp"
                href="https://breakoutmentors.com"
            >
                <Subrole
                    name="USACO Coach"
                    duration="2022-Present"
                >
                    One-on-one tutoring with high school students. 
                    Teaching relatively advanced problem solving skills (students vary from bronze to gold).
                    Custom problems are hosted on a self-written grading server.
                </Subrole>
            </Role>

            {/* <Role
                name="Neuro Leap"
                description="Neuro Leap is a startup that aims to help diagnose children with special needs. Unpaid and remote."
                img_src="/img/neuroleap.webp"
            >
                <Subrole
                    name="Software Engineer Intern"
                    duration="2022-23"
                >
                Used standard DevOps pipeline (vcs, CICD, etc) to make SwiftUI iOS app targeted towards specialists in remote areas. 
                Coordinated with backend team to design REST API.
                </Subrole>
            </Role> */}
        </SkillGroup>
    )
}
