/**
 *  COPYRIGHT © 2022 MANU BHAT
 *  
 *  ALL RIGHTS RESERVED
 * 
 */

import React, { PropsWithChildren } from "react";
import {StandardTemplate} from "../../framework/template";

import "./contact.css"


const angleSpan = 270;
const pivotAngle = -35;
const numElements = 6; // including gaps
const radius = 160;
const connectorRad = 105;


/* CONTACT 
 - small blurb about contacting, and also the social media pages

 - thinking of a sort of web of icons that originate as the bottom 4pi/3
   of a circle. First is email, and then it's sort of like branches 
   into the six or so social media platforms

   - actually we'll have it like Contact in bold slightly offset to the right
   - the actual blurb is to the top right
*/

export function LinkedIn(props: {fill?: string}) {
    const color = props.fill ?? "#0288d1";
    return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="80" viewBox="-1 -1 50 50"> <path fill={color} d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M14 19H18V34H14zM15.988 17h-.022C14.772 17 14 16.11 14 14.999 14 13.864 14.796 13 16.011 13c1.217 0 1.966.864 1.989 1.999C18 16.11 17.228 17 15.988 17zM35 24.5c0-3.038-2.462-5.5-5.5-5.5-1.862 0-3.505.928-4.5 2.344V19h-4v15h4v-8c0-1.657 1.343-3 3-3s3 1.343 3 3v8h4C35 34 35 24.921 35 24.5z"></path></svg>
}

export function GitHub() {
    return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="80" viewBox="0 0 30 30"> <path d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z"></path></svg>                                }

function Contact() {
    return (
        <StandardTemplate active = 'Contact' useStreaks={true}> 
            {/* has the social media pages and related content */ }
            {/* Below are some of my social media pages, my preferred */}
            {/* method of contact for formal matters is email [] */}
            {/* and for informal matters is discord []*/}

            {/* github, gmail, linked in, discord, facebook, instagram, twitter */}

            <div id="contact-wrapper">
                <div id="contact-page">

                    <div id="contact-paragraph">
                        <p>
                            Below are some of my social media pages and other contact methods.
                            For formal matters, please send an <a href='mailto:mbhat@ucsd.edu' target="_blank" rel="noopener noreferrer">email</a>.
                            For informal matters, I prefer <a href='https://www.discordapp.com/users/1007379494736244856' target="_blank" rel="noopener noreferrer">Discord</a>.    
                        </p>
                    </div>

                    <div id="contact-graph">
                        <div className="contact-label">
                            <p>Contact</p>

                            <span>
                                <MediaIcon href = 'https://www.github.com/enigmurl' angle={theta(pivotAngle, 1, angleSpan, numElements)}>
                                    <GitHub />
                                </MediaIcon>
                                <MediaIcon href = 'https://www.linkedin.com/in/manusbhat/' angle={theta(pivotAngle, 2, angleSpan, numElements)}>
                                    <LinkedIn />
                                </MediaIcon>
                                <MediaIcon 
                                    href = 'mailto:mbhat@ucsd.edu' 
                                    angle={theta(pivotAngle, 3, angleSpan, numElements)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="80" viewBox="-5 -5 59 59"><path fill="#4caf50" d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"></path><path fill="#1e88e5" d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"></path><polygon fill="#e53935" points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"></polygon><path fill="#c62828" d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"></path><path fill="#fbc02d" d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"></path></svg>                                
                                </MediaIcon>
                                <MediaIcon href = 'https://www.discordapp.com/users/1007379494736244856' angle={theta(pivotAngle, 4, angleSpan, numElements)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"width="75" height="75" viewBox="-5.5 -4 59 59"><path fill="#536dfe" d="M39.248,10.177c-2.804-1.287-5.812-2.235-8.956-2.778c-0.057-0.01-0.114,0.016-0.144,0.068	c-0.387,0.688-0.815,1.585-1.115,2.291c-3.382-0.506-6.747-0.506-10.059,0c-0.3-0.721-0.744-1.603-1.133-2.291	c-0.03-0.051-0.087-0.077-0.144-0.068c-3.143,0.541-6.15,1.489-8.956,2.778c-0.024,0.01-0.045,0.028-0.059,0.051	c-5.704,8.522-7.267,16.835-6.5,25.044c0.003,0.04,0.026,0.079,0.057,0.103c3.763,2.764,7.409,4.442,10.987,5.554	c0.057,0.017,0.118-0.003,0.154-0.051c0.846-1.156,1.601-2.374,2.248-3.656c0.038-0.075,0.002-0.164-0.076-0.194	c-1.197-0.454-2.336-1.007-3.432-1.636c-0.087-0.051-0.094-0.175-0.014-0.234c0.231-0.173,0.461-0.353,0.682-0.534	c0.04-0.033,0.095-0.04,0.142-0.019c7.201,3.288,14.997,3.288,22.113,0c0.047-0.023,0.102-0.016,0.144,0.017	c0.22,0.182,0.451,0.363,0.683,0.536c0.08,0.059,0.075,0.183-0.012,0.234c-1.096,0.641-2.236,1.182-3.434,1.634	c-0.078,0.03-0.113,0.12-0.075,0.196c0.661,1.28,1.415,2.498,2.246,3.654c0.035,0.049,0.097,0.07,0.154,0.052	c3.595-1.112,7.241-2.79,11.004-5.554c0.033-0.024,0.054-0.061,0.057-0.101c0.917-9.491-1.537-17.735-6.505-25.044	C39.293,10.205,39.272,10.187,39.248,10.177z M16.703,30.273c-2.168,0-3.954-1.99-3.954-4.435s1.752-4.435,3.954-4.435	c2.22,0,3.989,2.008,3.954,4.435C20.658,28.282,18.906,30.273,16.703,30.273z M31.324,30.273c-2.168,0-3.954-1.99-3.954-4.435	s1.752-4.435,3.954-4.435c2.22,0,3.989,2.008,3.954,4.435C35.278,28.282,33.544,30.273,31.324,30.273z"></path></svg>                                
                                </MediaIcon>
                            </span>

                            <span>
                                <Connector width="35px" radius={150} angle = {180 + pivotAngle}/>

                                <Connector angle = {theta(pivotAngle, 1, angleSpan, numElements)}/>
                                <Connector angle = {theta(pivotAngle, 2, angleSpan, numElements)}/>
                                <Connector angle = {theta(pivotAngle, 3, angleSpan, numElements)}/>
                                <Connector angle = {theta(pivotAngle, 4, angleSpan, numElements)}/>
                            </span>  
                        </div>
                    </div>

                    <div id="contact-mobile-graph">
                        <div className="contact-label">
                            <p>Contact</p>

                            <span>
                                <MediaIcon href = 'https://www.github.com/enigmurl' angle={theta(-90, 0, 360, numElements + 1)}>
                                    <GitHub />
                                </MediaIcon>
                                <MediaIcon href = 'https://www.linkedin.com/in/manusbhat/' angle={theta(-90, 1, 360, numElements + 1)}>
                                    <LinkedIn />
                                </MediaIcon>
                                <MediaIcon href = 'mailto:mbhat@ucsd.edu' angle={theta(-90, 2, 360, numElements + 1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="80" viewBox="-5 -5 59 59"><path fill="#4caf50" d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"></path><path fill="#1e88e5" d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"></path><polygon fill="#e53935" points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"></polygon><path fill="#c62828" d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"></path><path fill="#fbc02d" d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"></path></svg>                                
                                </MediaIcon>
                                <MediaIcon href = 'https://www.discordapp.com/users/1007379494736244856' angle={theta(-90, 6, 360, numElements + 1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"width="80" height="80" viewBox="-3.5 -2 55 55"><path fill="#536dfe" d="M39.248,10.177c-2.804-1.287-5.812-2.235-8.956-2.778c-0.057-0.01-0.114,0.016-0.144,0.068	c-0.387,0.688-0.815,1.585-1.115,2.291c-3.382-0.506-6.747-0.506-10.059,0c-0.3-0.721-0.744-1.603-1.133-2.291	c-0.03-0.051-0.087-0.077-0.144-0.068c-3.143,0.541-6.15,1.489-8.956,2.778c-0.024,0.01-0.045,0.028-0.059,0.051	c-5.704,8.522-7.267,16.835-6.5,25.044c0.003,0.04,0.026,0.079,0.057,0.103c3.763,2.764,7.409,4.442,10.987,5.554	c0.057,0.017,0.118-0.003,0.154-0.051c0.846-1.156,1.601-2.374,2.248-3.656c0.038-0.075,0.002-0.164-0.076-0.194	c-1.197-0.454-2.336-1.007-3.432-1.636c-0.087-0.051-0.094-0.175-0.014-0.234c0.231-0.173,0.461-0.353,0.682-0.534	c0.04-0.033,0.095-0.04,0.142-0.019c7.201,3.288,14.997,3.288,22.113,0c0.047-0.023,0.102-0.016,0.144,0.017	c0.22,0.182,0.451,0.363,0.683,0.536c0.08,0.059,0.075,0.183-0.012,0.234c-1.096,0.641-2.236,1.182-3.434,1.634	c-0.078,0.03-0.113,0.12-0.075,0.196c0.661,1.28,1.415,2.498,2.246,3.654c0.035,0.049,0.097,0.07,0.154,0.052	c3.595-1.112,7.241-2.79,11.004-5.554c0.033-0.024,0.054-0.061,0.057-0.101c0.917-9.491-1.537-17.735-6.505-25.044	C39.293,10.205,39.272,10.187,39.248,10.177z M16.703,30.273c-2.168,0-3.954-1.99-3.954-4.435s1.752-4.435,3.954-4.435	c2.22,0,3.989,2.008,3.954,4.435C20.658,28.282,18.906,30.273,16.703,30.273z M31.324,30.273c-2.168,0-3.954-1.99-3.954-4.435	s1.752-4.435,3.954-4.435c2.22,0,3.989,2.008,3.954,4.435C35.278,28.282,33.544,30.273,31.324,30.273z"></path></svg>                                
                                </MediaIcon>
                            </span>

                            <span>
                                <Connector angle = {theta(-90, 0, 360, numElements + 1)}/>
                                <Connector angle = {theta(-90, 1, 360, numElements + 1)}/>
                                <Connector angle = {theta(-90, 2, 360, numElements + 1)}/>
                                <Connector angle = {theta(-90, 3, 360, numElements + 1)}/>
                                <Connector angle = {theta(-90, 4, 360, numElements + 1)}/>
                                <Connector angle = {theta(-90, 5, 360, numElements + 1)}/>
                                <Connector angle = {theta(-90, 6, 360, numElements + 1)}/>
                            </span>  
                        </div>
                    </div>
                </div>
            </div>

        </StandardTemplate>
    );
}

function MediaIcon(props: PropsWithChildren<{href: string, angle: number}>) {
    const [cosT, sinT] = cis(radius, props.angle);

    return (
        <span className="contact-media-icon" style={{
            transform: `translateX(calc(-50% + ${cosT}px )) translateY(calc(50% - ${sinT}px))`
        }}> 
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        </span>
    )
}

function cis(radius: number, theta: number) {
    const rads = theta * Math.PI / 180;

    const cosT = Math.cos(rads) * radius;
    const sinT = Math.sin(rads) * radius;

    return [cosT, sinT];
}

function theta(pivotAngle: number, index: number, angleSpan: number, numElements: number) {
    return pivotAngle + angleSpan / 2 - angleSpan * index / (numElements - 1);
}

function Connector(props: {angle: number, radius?: number, width?: string}) {
    const [cosT, sinT] = cis(props.radius || connectorRad, props.angle);
    const width = props.width || "10px"
    
    return (
        <span className="contact-connector" style={{
            width: width,
            height: width, 
            transform: `translateX(calc(-50% + ${cosT}px )) translateY(calc(50% - ${sinT}px))`
        }}/>
    )
}

export default Contact;