/**
 *  COPYRIGHT © 2022 MANU BHAT
 *  
 *  ALL RIGHTS RESERVED
 * 
 */

import { Skill, SkillGroup } from "../../framework/skill_list";


// this is depressing 
export default function Honors() {
    return (
         <SkillGroup id='honors' title='Honors'>
            <Skill
                name='ICPC'
                desc="2nd in Southern California Regional (2024-25)"
                img_src="/img/icpc.web"
                href="https://icpc.global/"
            />

            <Skill
                name='Codeforces'
                desc='Master rank (2024)'
                img_src="/img/codeforces.webp"
                href="https://codeforces.com/"
            />

            <Skill
                name='picoCTF'
                desc='#7 global (2024)'
                img_src="/img/picoctf.webp"
                href="https://picoctf.org"
            />

            <Skill
                name='USACO'
                desc="Platinum rank (2022)"
                href="http://usaco.org/index.php"
                img_src='/img/usaco_plat.webp'
            />
        </SkillGroup>
    )
}
